import * as R from "ramda";
import { searchApi } from "common/api/search";
import { DataType } from "common/entities/entity-column/data-type/types";
import { EntityColumn } from "common/entities/entity-column/types";
import { Entity } from "common/entities/types";
import {
  isValidDynamicValue,
  resolveDynamicValue,
  resolveDynamicValueForFilter,
} from "common/form/dynamic-values";
import { addFilter } from "common/query/filter";
import { addToSelectQuery } from "common/query/select";
import {
  defaultFormValue,
  getPropertiesWithTemporaryIds,
} from "common/record/edit/value";
import { FormValue } from "common/record/types";
import { Context } from "common/types/context";
import { Form } from "common/types/forms";
import { CancellablePromise } from "common/types/promises";
import { Properties, Record } from "common/types/records";

interface ColumnDataTypes {
  [columnName: string]: EntityColumn;
}

interface DefaultWithDynamicValues {
  key: string;
  dataType: DataType;
  value: any;
  entity: Entity;
}

interface DefaultsWithDependencies {
  defaultsWithDynamicValues: DefaultWithDynamicValues[];
  promiseArray: Array<CancellablePromise<Record[]>>;
}

/**
 * This function resolves the `{user}` dynamic value with an `id` of a related record which `email`
 * field matches the current user's email
 */
const getFkDynamicValueResolveQuery = (
  dataType: DataType,
  entity: Entity,
  value: string,
  context: Context,
) => {
  const filterValue = resolveDynamicValueForFilter(
    context,
    dataType,
    entity,
    value,
  );
  const query = addFilter(
    { name: "email", op: "eq", value: filterValue },
    {
      entity: entity.name,
      query: addToSelectQuery([{ name: "id" }], entity.query),
    },
  );
  return searchApi(context.apiCall).runQuery(query) as CancellablePromise<
    Record[]
  >;
};

const getColumnsByName = (entity: Entity): { [ind: string]: EntityColumn } => {
  return entity.columns.reduce((acc: ColumnDataTypes, column: EntityColumn) => {
    return R.mergeRight(acc, { [column.name]: column });
  }, {});
};

export const getFormWithResolvedDefaults = (
  context: Context,
  entity: Entity,
  form: Form,
  defaultForm: FormValue,
  defaultProperties: Properties,
  omitDefaultsForKeys: string[] = [],
  resolveDynamicValues: boolean = true,
): CancellablePromise<FormValue> => {
  const defaults = form && form.settings.defaults;
  const columns = getColumnsByName(entity);

  const { defaultsWithDynamicValues, promiseArray } = R.keys(defaults).reduce(
    (acc: DefaultsWithDependencies, key: string) => {
      const value = defaults[key];
      const { dataType, relatedEntity } = columns[key];
      const columnRelatedEntity = context.entities[relatedEntity];
      const isDynamicValue =
        resolveDynamicValues &&
        isValidDynamicValue(dataType, columnRelatedEntity, value);

      const newData = isDynamicValue
        ? [{ key, dataType, value, entity: columnRelatedEntity }]
        : [];
      const newPromise = isDynamicValue
        ? dataType === "fk"
          ? [
              getFkDynamicValueResolveQuery(
                dataType,
                columnRelatedEntity,
                value,
                context,
              ),
            ]
          : [CancellablePromise.resolve([])]
        : [];

      return R.mergeRight(acc, {
        defaultsWithDynamicValues:
          acc.defaultsWithDynamicValues.concat(newData),
        promiseArray: acc.promiseArray.concat(newPromise),
      });
    },
    { defaultsWithDynamicValues: [], promiseArray: [] },
  );

  if (!promiseArray.length) return CancellablePromise.resolve(defaultForm);

  return CancellablePromise.all(promiseArray)
    .then((data: Record[][]) => {
      const resolved = defaultsWithDynamicValues.reduce(
        (acc, object, index: number) => {
          const { key, dataType, value, entity } = object;

          if (R.includes(key, omitDefaultsForKeys)) {
            return acc;
          }

          const newValue = resolveDynamicValue(
            dataType,
            entity,
            value,
            context,
            data[index],
          );

          return R.mergeRight(acc, { [key]: newValue });
        },
        {},
      );

      const newDefaults = resolved
        ? (R.mergeRight(defaultProperties, resolved) as Properties)
        : defaultProperties;

      return defaultFormValue(
        true,
        getPropertiesWithTemporaryIds(entity, newDefaults),
        form,
      );
    })
    .catch(() => defaultForm);
};
