import { Entities, Entity } from "common/entities/types";
import { getFields } from "common/query-builder/functions";
import { RuleActions, WorkflowRule } from "common/types/workflows";
import { isApproveRequestActionValid } from "x/account-settings/workflows/form/actions/approve-request/functions";
import {
  isEmailNotificationValid,
  areEmailReceiversValid,
} from "x/account-settings/workflows/form/actions/email-notification/functions";
import { isUpdateValueActionValid } from "x/account-settings/workflows/form/actions/update-table-value/functions";
import { isCreateRecordValid } from "./create-record/functions";
import { isChangeStatusActionValid } from "./work-order-action/functions";
import { isAttachPdfActionValid } from "./attach-pdf/functions";

const isActionEnabled =
  (actions: RuleActions) => (actionName: keyof RuleActions) =>
    !!(actions && actionName in actions);

const areAnyActionsEnabled = (actions: RuleActions) => {
  const isEnabled = isActionEnabled(actions);
  return (
    isEnabled("updatetablevalue") ||
    isEnabled("sendnotification") ||
    isEnabled("approverequest") ||
    isEnabled("addrecord") ||
    isEnabled("archive") ||
    isEnabled("changeworkorderstatus") ||
    isEnabled("attachpdf")
  );
};

const isUpdateTableValueValid = (actions: RuleActions) =>
  !isActionEnabled(actions)("updatetablevalue") ||
  isUpdateValueActionValid(actions?.updatetablevalue);

const isApproveRequestValid = (actions: RuleActions) =>
  !isActionEnabled(actions)("approverequest") ||
  isApproveRequestActionValid(actions.approverequest);

const isSendNotificationValid = (actions: RuleActions) =>
  !isActionEnabled(actions)("sendnotification") ||
  (isEmailNotificationValid(actions?.sendnotification) &&
    areEmailReceiversValid(actions?.sendnotification));

const isAddRecordValid = (actions: RuleActions) =>
  !isActionEnabled(actions)("addrecord") ||
  isCreateRecordValid(actions?.addrecord);

const isChangeWorkOrderStatusValid = (actions: RuleActions) =>
  !isActionEnabled(actions)("changeworkorderstatus") ||
  isChangeStatusActionValid(actions?.changeworkorderstatus);

const isAttachPdfValid = (actions: RuleActions) =>
  !isActionEnabled(actions)("attachpdf") ||
  isAttachPdfActionValid(actions?.attachpdf);

export const areActionsValid = (rule: WorkflowRule) => {
  const actions = rule?.actions;

  return (
    areAnyActionsEnabled(actions) &&
    isUpdateTableValueValid(actions) &&
    isApproveRequestValid(actions) &&
    isSendNotificationValid(actions) &&
    isAddRecordValid(actions) &&
    isChangeWorkOrderStatusValid(actions) &&
    isAttachPdfValid(actions)
  );
};

export const getUpdateSourceFieldsForEntity = (
  entity: Entity,
  entities: Entities,
) =>
  getFields(entities, {
    entity: entity.name,
    query: { ...entity.query, joins: entity.joins.filter((j) => j.outbound) },
  });
