import { JSX } from "react";
import { Node, NodeLinkProps } from "./types";
import { NodeLink } from "./node-link";

interface PropTypes {
  node: Node;
  onClick?: (node: Node) => any;
  isDisabled?: (node: Node) => boolean;
  selected?: Node;
  textToHighlight?: string;
  onAdd?: (node: Node) => any;
  onExpand?: (node: Node) => any;
  isExpanded?: boolean;
  onDisplay?: (props: NodeLinkProps) => JSX.Element;
  withNodeIcon?: boolean;
}

export const NodeComponent = ({
  node,
  onClick,
  isDisabled,
  selected,
  textToHighlight,
  onAdd,
  onExpand,
  isExpanded,
  onDisplay,
  withNodeIcon,
}: PropTypes) => {
  const levelPadding = (node?.level ?? 0) * 20;

  const onNodeAdd = () => {
    onAdd(node);
  };

  const onNodeExpand = () => {
    onExpand(node);
  };

  const onNodeClick = () => {
    onClick(node);
  };

  const displayNode = () => {
    const disabled = isDisabled ? isDisabled(node) : false;
    const nodeLinkProps = {
      node,
      onClick: onClick && !disabled ? onNodeClick : undefined,
      isSelected: selected?.id === node.id,
      textToHighlight: textToHighlight,
      onExpand: onExpand ? onNodeExpand : undefined,
      isExpanded: isExpanded ?? false,
      isDisabled: disabled,
      withNodeIcon,
    };

    return onDisplay ? (
      onDisplay(nodeLinkProps)
    ) : (
      <NodeLink {...nodeLinkProps} />
    );
  };

  if (node?.isGroup) {
    return (
      <div className="x-tree-node" style={{ paddingLeft: levelPadding }}>
        <div className="x-node-head">
          {displayNode()}
          {onAdd ? (
            <button
              className={`x-tree-add qa-tree-add-${node.id}`}
              aria-label={_("Add")}
              onClick={onNodeAdd}
            >
              <i className="fa fa-plus-circle" />
            </button>
          ) : undefined}
        </div>
      </div>
    );
  }

  return (
    <div className="x-tree-node" style={{ paddingLeft: levelPadding }}>
      <div className="x-node-leaf">{displayNode()}</div>
    </div>
  );
};
