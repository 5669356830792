import * as React from "react";
import { ReactHighlightWords } from "common/vendor-wrappers/react-highlight-words";
import { arrayToString } from "common/utils/array";
import { NodeLinkPropTypes } from "./types";

export const NodeLink = ({
  node,
  selected,
  textToHighlight,
  isDisabled,
  onClick,
}: NodeLinkPropTypes) => {
  if (!node) return null;

  const className = arrayToString([
    "x-tree-node-link",
    node === selected ? "selected" : undefined,
    isDisabled ? "x-disabled" : undefined,
    `qa-${node.name}`,
  ]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.code === "Enter") {
      e.preventDefault();
      onClick?.();
    }
  };

  const onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    onClick?.();
  };

  return node ? (
    isDisabled ? (
      <span className={className}>
        <ReactHighlightWords text={node.label} search={textToHighlight} />
      </span>
    ) : (
      <a
        href="#"
        onKeyDown={onKeyDown}
        onClick={onLinkClick}
        className={className}
      >
        <ReactHighlightWords text={node.label} search={textToHighlight} />
      </a>
    )
  ) : null;
};
