import * as R from "ramda";
import { JSX, useEffect, useState } from "react";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { defaultFor } from "common";
import { CancellablePromise } from "common/types/promises";
import { InputWithSearch } from "common/widgets/input-with-search";
import { LoadingIcon } from "common/widgets/loading-icon";
import { NodeComponent } from "common/widgets/lazy-tree/node";
import { Node, NodeLinkProps } from "common/widgets/lazy-tree/types";
import { getNodesForTree } from "common/widgets/lazy-tree/functions";
import { Sizer, Sizes } from "common/widgets/sizer";
import { classNames } from "common/utils/jsx";

import "./index.scss";

const DEFAULT_LIST_HEIGHT = 500;
const DEFAULT_ITEM_SIZE = 25;

interface PropTypes {
  onClick: (node: Node, nodes?: Node[]) => any;
  className?: string;
  selected?: Node;
  withNodeIcons?: boolean;
  requestRecords: () => CancellablePromise<Node[]>;
  onDisplay?: (props: NodeLinkProps) => JSX.Element;
  isDisabled?: (node: Node) => boolean;
  itemSize?: number;
}

export const LazyTree = ({
  onClick,
  className,
  selected,
  withNodeIcons,
  requestRecords,
  itemSize,
  onDisplay,
  isDisabled,
}: PropTypes) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [displayNodes, setDisplayNodes] = useState<Node[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    requestRecords()
      .then((nodes) => {
        setNodes(nodes);
        setDisplayNodes(getNodesForTree(nodes, searchTerm, expandedNodes));
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setDisplayNodes(getNodesForTree(nodes, searchTerm, expandedNodes));
  }, [searchTerm, expandedNodes]);

  const isExpanded = (node: Node) => {
    return expandedNodes.includes(node.id);
  };

  const onExpand = (node: Node) => {
    const isAlreadyExpanded = expandedNodes.includes(node.id);

    const newExpandedNodes = isAlreadyExpanded
      ? expandedNodes.filter((n) => n !== node.id)
      : expandedNodes.concat([node.id]);

    setExpandedNodes(newExpandedNodes);
  };

  const onNodeClick = (node: Node) => {
    onClick(node, nodes);
  };

  const listItem = (
    listProps: ListChildComponentProps = defaultFor<ListChildComponentProps>(),
  ) => {
    const { index, style } = listProps;

    return (
      <div style={style}>
        <NodeComponent
          node={displayNodes[index]}
          selected={selected}
          textToHighlight={searchTerm}
          onClick={onNodeClick}
          onExpand={onExpand}
          withNodeIcon={withNodeIcons}
          isExpanded={isExpanded(displayNodes[index])}
          isDisabled={isDisabled}
          onDisplay={onDisplay}
        />
      </div>
    );
  };

  const renderTree = ({ width, height }: Sizes) => {
    return (
      <VariableSizeList
        className="x-virtual-list"
        height={height ?? DEFAULT_LIST_HEIGHT}
        width={width}
        itemCount={displayNodes.length}
        itemSize={R.always(itemSize ?? DEFAULT_ITEM_SIZE)}
      >
        {listItem}
      </VariableSizeList>
    );
  };

  const onSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  if (isLoading) return <LoadingIcon />;

  return (
    <div className={classNames(["x-lazy-tree", className])}>
      <InputWithSearch value={searchTerm} onChange={onSearchTermChange} />
      {displayNodes?.length ? <Sizer render={renderTree} /> : _("No results")}
    </div>
  );
};
