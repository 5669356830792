import * as R from "ramda";
import { MouseEvent } from "react";
import { defaultFor, toDashedNoSpace } from "common";
import { Entity } from "common/entities/types";
import { Layout } from "common/form/types";
import { hasPermissionToRead } from "common/functions/roles";
import { shouldDisableRelatedEntity } from "common/record/disabled-related-entity";
import { StandardUiValue } from "common/record/types";
import { Context } from "common/types/context";
import { defaultRecord, Record } from "common/types/records";
import { arrayToString } from "common/utils/array";
import { InjectedProps, toggleable } from "common/widgets/toggleable";
import { withValue, WithValue } from "common/with-value";
import { getTooltipProps } from "common/vendor-wrappers/react-tooltip";
import {
  AUDIT_TRAIL,
  COMMENTS,
  GENERAL,
  getLabelFor,
  OTHER,
  OVERVIEW,
  RELATED,
  REPORTS,
} from "../utils";
import { getRelated, getRelatedChanges, getSidebarElements } from "./functions";
import { SidebarElement } from "./types";

interface PropTypes {
  context: Context;
  mapElements?: (elements: SidebarElement[]) => SidebarElement[];
  entity: Entity;
  record: Record;
  ui?: StandardUiValue;
  layout: Layout;
  disabled: boolean;
  editing?: boolean;
  omitAuditTrail?: boolean;
  commentsCount?: number;
  isGeneralInformationInvalid?: boolean;
}

export type Props = PropTypes & InjectedProps & WithValue<SidebarElement>;

const overview: SidebarElement = {
  label: OVERVIEW,
};

export const generalInfo: SidebarElement = {
  label: GENERAL,
  isChild: true,
  isInvalid: false,
};

const relatedTitle: SidebarElement = {
  label: RELATED,
};

const reportsTitle: SidebarElement = {
  label: REPORTS,
};

const otherTitle: SidebarElement = {
  label: OTHER,
};

export const auditTrail: SidebarElement = {
  label: AUDIT_TRAIL,
};

const childify = R.mergeRight({ isChild: true });

const defaultUi = defaultFor<StandardUiValue>();

const SidebarComp = ({
  context,
  entity,
  layout,
  disabled,
  omitAuditTrail,
  record = defaultRecord,
  ui = defaultUi,
  value = generalInfo,
  editing = false,
  toggleFn,
  toggleOpened,
  setValue,
  mapElements,
  commentsCount,
  isGeneralInformationInvalid,
}: Props) => {
  const uiRelated = ui.related?.form;
  const { entities } = context;
  const { related, properties } = record;
  const { reports, relatedEntities, staticEntries = [] } = layout;

  const relatedForSidebar = relatedEntities?.length
    ? relatedEntities.filter((e) => !e.showInOverview)
    : relatedEntities;

  const relatedEnts =
    !relatedForSidebar || relatedForSidebar.length
      ? getRelated(
          context,
          entity,
          relatedForSidebar,
          getRelatedChanges(uiRelated, related),
        )
      : [];

  const relatedSection =
    relatedEnts.length > 0
      ? [relatedTitle, ...relatedEnts.map(childify)]
      : relatedEnts;

  const rpts = (reports?.sidebar || []).map((report) => ({
    label: report.label,
    reportId: report.id,
  }));
  const reportsSection =
    rpts.length > 0 ? [reportsTitle, ...rpts.map(childify)] : rpts;

  const otherToOmit = !properties?.id ? [COMMENTS] : undefined;
  const otherElements = getSidebarElements(
    entity,
    context,
    staticEntries,
    otherToOmit,
  );
  const others =
    omitAuditTrail ||
    !hasPermissionToRead(context.userTypes, context.role, "AuditTrail") ||
    !properties?.id
      ? [...otherElements]
      : [...otherElements, auditTrail];

  const applyCommentsCount = (element: SidebarElement): SidebarElement => ({
    ...element,
    count: element.label === COMMENTS ? commentsCount : undefined,
  });

  const otherSection: SidebarElement[] =
    others.length > 0
      ? [otherTitle, ...others.map(childify).map(applyCommentsCount)]
      : others;

  const overviewSection = [
    overview,
    { ...generalInfo, isInvalid: isGeneralInformationInvalid },
  ];

  const columns: SidebarElement[] = R.filter(
    (c) => !!c,
    [...overviewSection, ...relatedSection, ...reportsSection, ...otherSection],
  );
  const sidebarElements = mapElements ? mapElements(columns) : columns;

  return (
    <div
      className={
        "x-sidebar x-padding-10" +
        (disabled ? " x-disabled" : "") +
        (editing ? " x-disable-grandchildren" : "") +
        (toggleOpened ? " x-show-sidebar" : " x-hide-sidebar")
      }
    >
      <div className="x-sidebar-dropdown" onClick={toggleFn}>
        <p>{value.label}</p>
        <i className="fa fa-chevron-down" />
      </div>
      <ul className={`list-group ${editing ? "x-padding-bottom-40" : ""}`}>
        {sidebarElements.map((element, i) => {
          const { count, entity: itemEntity } = element;
          const label = getLabelFor(element.label);
          const countItem = R.is(Number, count) ? (
            <span className="badge pull-right x-count">{count}</span>
          ) : undefined;
          const invalidIndicator = element.isInvalid ? (
            <span
              className="x-invalid-element"
              {...getTooltipProps(
                _("The provided input is invalid. Please review the details"),
                "error",
              )}
            >
              <i className="fa fa-exclamation-circle" />
            </span>
          ) : undefined;

          const isActive = itemEntity === value.label || label === value.label;
          const disabledEntity =
            itemEntity &&
            shouldDisableRelatedEntity(
              entity,
              entities[itemEntity],
              properties,
            );
          const isDisabled = !!disabledEntity;
          const itemTitle =
            isDisabled && disabledEntity.reason
              ? disabledEntity.reason.replace("{LABEL}", label)
              : "";

          const className = arrayToString([
            "list-group-item x-ellipsis",
            isActive ? "selected" : undefined,
            element.isChild ? "list-group-item-child" : undefined,
            element.isGrandChild
              ? "x-list-group-item-grandchild x-padding-left-40"
              : undefined,
            isDisabled ? disabledEntity.className : undefined,
            itemEntity ? `qa-${itemEntity}` : undefined,
            `qa-${toDashedNoSpace(label)}`,
          ]);

          const allowClick =
            !(disabled || (editing && element.isGrandChild)) &&
            element.isChild &&
            !isDisabled;
          const onClick = () => {
            if (toggleOpened) {
              toggleFn();
            }
            setValue(element);
          };
          const onLinkClick = (e: MouseEvent) => {
            e.preventDefault();
            onClick();
          };

          return (
            <li
              key={i}
              className={className}
              onClick={allowClick ? onClick : undefined}
            >
              {element.isChild ? (
                <a title={itemTitle} href="#" onClick={onLinkClick}>
                  {countItem}
                  {label}
                  {invalidIndicator}
                </a>
              ) : (
                <span>
                  {countItem}
                  {label}
                  {invalidIndicator}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const Sidebar = toggleable({ defaultOpened: false })(
  withValue<SidebarElement, PropTypes & InjectedProps>(SidebarComp, "Sidebar"),
);
