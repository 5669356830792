import * as R from "ramda";
import { useEffect, useState } from "react";
import { searchApi } from "common/api/search";
import { Context } from "common/types/context";
import { ValueProps } from "common/with-value-for";
import { Entity } from "common/entities/types";
import { Query } from "common/query/types";
import { Node } from "common/widgets/lazy-tree/types";
import { LazyTree } from "common/widgets/lazy-tree";
import {
  getFkToTree,
  getInFilter,
  getRecordsQuery,
} from "x/records/list/functional-location/functions";

import "./index.scss";
import { FunctionalLocationData } from "x/records/list/functional-location/types";

interface PropTypes extends ValueProps<Query> {
  context: Context;
  entity: Entity;
}

const defaultFilter = {
  and: [{ name: "isDeleted", op: "isfalse" }],
};

export const FunctionalLocation = ({
  context,
  entity,
  value,
  onChange,
}: PropTypes) => {
  const [selectedNode, setSelectedNode] = useState<Node>(undefined);

  useEffect(() => {
    if (R.equals(value?.filter, defaultFilter)) {
      setSelectedNode(undefined);
    }
  }, [value]);

  const getFunctionalLocationRelatedEntity = () => {
    const foreignKeyToTreeEntity = getFkToTree(entity, context);
    return context.entities[foreignKeyToTreeEntity.relatedEntity];
  };

  const requestRecords = () => {
    return searchApi(context.apiCall)
      .runQuery(getRecordsQuery(getFunctionalLocationRelatedEntity()))
      .then((data: FunctionalLocationData[]) => {
        const nodes: Node[] = data.map((record) => ({
          id: record.id,
          label: record.title.toString(),
          isGroup: !!record.childrenCount,
          parentId: record.parentId,
          data: record,
        }));
        return nodes;
      });
  };

  const onNodeSelected = (node: Node, nodes: Node[]) => {
    const newFilter =
      node === selectedNode
        ? { and: [{ name: "isDeleted", op: "isfalse" }] }
        : getInFilter(entity, context, node, nodes);

    onChange({ ...value, filter: newFilter });
    setSelectedNode(node === selectedNode ? undefined : node);
  };

  return (
    <LazyTree
      withNodeIcons={true}
      className="x-functional-location"
      selected={selectedNode}
      onClick={onNodeSelected}
      requestRecords={requestRecords}
    />
  );
};
