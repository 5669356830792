import { Component } from "common/component";
import { findChildren } from "common/utils/tree";
import { TreePropTypes, Node } from "./types";
import { NodeLink } from "./node-link";

interface PropTypes extends TreePropTypes {
  node: Node;
  expandedByDefault: boolean;
}

interface StateType {
  isExpanded: boolean;
}

export class NodeComponent extends Component<PropTypes, StateType> {
  static readonly displayName = "NodeComponent";

  constructor(props: PropTypes) {
    super(props);
    this.state = {
      isExpanded: props.expandedByDefault ?? true,
    };
  }

  onAdd = () => {
    const { node, onAdd } = this.props;
    onAdd(node);
  };

  onExpand = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  displayNode = () => {
    const { node, selected, textToHighlight, isDisabled, onClick, onDisplay } =
      this.props;
    const disabled = isDisabled ? isDisabled(node) : false;
    const nodeLinkProps = {
      node,
      onClick: onClick && !disabled ? onClick.bind(null, node) : undefined,
      selected,
      textToHighlight: textToHighlight,
      onExpand: this.onExpand,
      isExpanded: this.state.isExpanded,
      isDisabled: disabled,
    };
    if (onDisplay) {
      return onDisplay(nodeLinkProps);
    }
    return <NodeLink {...nodeLinkProps} />;
  };

  displayChildren = () => {
    const {
      nodes,
      node,
      selected,
      isDisabled,
      textToHighlight,
      expandedByDefault,
      onClick,
      onDisplay,
      onAdd,
      onExpand,
    } = this.props;

    return (
      <div className="x-tree-children">
        {findChildren(nodes, node).map((child, i) => (
          <NodeComponent
            key={i}
            nodes={nodes}
            node={child}
            selected={selected}
            isDisabled={isDisabled}
            textToHighlight={textToHighlight}
            expandedByDefault={expandedByDefault}
            onAdd={onAdd}
            onClick={onClick}
            onDisplay={onDisplay}
            onExpand={onExpand}
          />
        ))}
      </div>
    );
  };

  render() {
    const { node, onAdd } = this.props;
    if (node?.isGroup) {
      return (
        <div className="x-tree-node">
          <div className="x-node-head">
            {this.displayNode()}
            {onAdd ? (
              <button
                className={`x-tree-add qa-tree-add-${node.name}`}
                aria-label={_("Add")}
                onClick={this.onAdd}
              >
                <i className="fa fa-plus-circle" />
              </button>
            ) : undefined}
          </div>
          {this.state.isExpanded ? this.displayChildren() : undefined}
        </div>
      );
    }
    return (
      <div className="x-tree-node">
        <div className="x-node-leaf">{this.displayNode()}</div>
      </div>
    );
  }
}
