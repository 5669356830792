import { Component } from "react";
import { Table } from "common/query/table/table";
import { TableValue } from "common/query/table/types";
import { Action } from "common/record/actions/types";
import { Context } from "common/types/context";
import { ApiErrorResponse } from "common/types/error";
import { PartReorderRecord } from "common/types/part-reorder";
import { ApiError } from "common/ui/api-error";
import { PaginationFooter } from "common/widgets/pagination/footer";
import { ValueProps } from "common/with-value-for";
import {
  canCreatePoForRecords,
  getReorderListColumnDefinitions,
  getReorderListConfig,
} from "./functions";
import { PurchaseOrderController } from "./purchase-order";
import { CREATE_PURCHASE_ORDER_ACTION, PartsReorderRibbon } from "./ribbon";
import { ListValue } from "./types";

interface PropTypes extends ValueProps<ListValue> {
  context: Context;
  loadingRecords: boolean;
  error: ApiErrorResponse;
  page: number;
  pageSize: number;
  totalRecords: number;
  onReload: () => void;
  onChangePage: (page: number) => void;
  onChangePageSize: (size: number) => void;
}

const starred: string[] = [];

interface StateTypes {
  action: Action;
}

export class PartsReorderContent extends Component<PropTypes, StateTypes> {
  static readonly displayName = "PartsReorderContent";

  state: StateTypes = {
    action: undefined,
  };

  onTableChange = (table: TableValue) => {
    const { onChange, value } = this.props;
    onChange({ ...value, table }); // TODO filter
  };

  onActionSelect = (action: Action) => {
    this.setState({ action });
  };

  onActionDismiss = (reload: boolean) => {
    this.setState({ action: undefined });
    reload ? this.props.onReload() : undefined;
  };

  getUi = () => {
    const {
      onReload,
      loadingRecords,
      context,
      value,
      page,
      totalRecords,
      pageSize,
      onChangePage,
      onChangePageSize,
    } = this.props;
    const { action } = this.state;

    switch (action?.name) {
      case CREATE_PURCHASE_ORDER_ACTION:
        return (
          <PurchaseOrderController
            context={context}
            dismiss={this.onActionDismiss}
            reorderRecords={value.table.selected as PartReorderRecord[]}
          />
        );
      default:
        return (
          <>
            <Table
              context={context}
              columnDefinitions={getReorderListColumnDefinitions(context)}
              reload={onReload}
              withLinks={true}
              goTo={undefined}
              starred={starred}
              toggleStar={undefined}
              isLoading={loadingRecords}
              config={getReorderListConfig(context, value.table.data)}
              value={value.table}
              onChange={this.onTableChange}
            />
            <PaginationFooter
              loadingRecords={loadingRecords}
              page={page}
              pageSize={pageSize}
              totalRecords={totalRecords}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </>
        );
    }
  };

  render() {
    const { action } = this.state;
    const { onReload, loadingRecords, context, value, error } = this.props;
    const { scope, site } = context;

    if (error) return <ApiError error={error} />;

    return (
      <div className="x-container-with-ribbon">
        <div className="x-content-with-ribbon">
          <PartsReorderRibbon
            action={action}
            scope={scope}
            loadingRecords={loadingRecords}
            onRefresh={onReload}
            onActionSelect={this.onActionSelect}
            showActionsButton={
              !loadingRecords &&
              !site.isGroup &&
              canCreatePoForRecords(context, value.table.selected)
            }
          />

          <div className="x-records-list">
            <div className="x-records-list-content x-parts-reorder-list">
              {this.getUi()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
