import { getLocalizedName, toLowerNoSpace } from "common";
import { Culture, EN_US } from "common/culture/supported-cultures";
import { behaveAs } from "common/entities";
import { Entities, Entity } from "common/entities/types";
import { isEntity, isReport, isStatic } from "common/functions/preferences";
import { hasPermissionToRead } from "common/functions/roles";
import { Context } from "common/types/context";
import {
  EntitySetting,
  MenuEntry,
  MenuEntrySetting,
  ReportSetting,
  StaticEntry,
  StaticSetting,
} from "common/types/preferences";
import { RecentlyViewed } from "common/types/recently-viewed";
import { Report } from "common/types/reports";
import { Site } from "common/types/sites";
import { eventLabel, trackEvent } from "common/utils/mixpanel";

export const canView = (context: Context, category: string) => {
  const { userTypes, role } = context;

  return (
    category.toLowerCase() === "dashboard" ||
    category === "References" ||
    (category === "DataImport" &&
      hasPermissionToRead(userTypes, role, "Import")) ||
    (category === "Scheduler2" &&
      hasPermissionToRead(userTypes, role, "Scheduler")) ||
    hasPermissionToRead(userTypes, role, category)
  );
};

const getCss = (entity: Entity) => {
  if (entity.css) return entity.css;
  if (behaveAs("WorkOrder", entity)) return "fa-wrench";
  if (behaveAs("Assignment", entity)) return "fa-calendar-check-o";
  if (behaveAs("Asset", entity)) return "fa-truck";
  return "fa-file-o";
};

export const getMenuItemClass = (item: string) =>
  item && `x-${toLowerNoSpace(item)}-item`;

export const getStaticMenuEntry = ({
  name,
  label,
  icon,
}: StaticEntry): MenuEntry => ({
  label,
  icon,
  link: `#/:site/${name}`,
  canExplore: false,
  itemClass: getMenuItemClass(name),
});

const staticToSetting = (se: StaticEntry): StaticSetting => ({
  name: se.name,
  icon: se.icon,
});

// --- User Menu functions ---------------------------------------------------//

const getDefaultReportLabel = (id: number) =>
  id ? `${_("Report")} ${id}` : undefined;

export const getReportLabelBySettings = (
  culture: Culture,
  setting: ReportSetting,
) =>
  setting.labels?.[culture] ||
  setting.labels?.[EN_US] ||
  getDefaultReportLabel(setting.reportId);

export const getReportLabelByRecord = (culture: Culture, report: Report) =>
  report?.labels?.[culture]?.name ||
  report?.labels?.[EN_US]?.name ||
  report?.label ||
  report?.name ||
  getDefaultReportLabel(report?.id);

export const getLabel = (
  culture: Culture,
  setting: MenuEntrySetting,
): string => {
  if (!setting) return undefined;

  if (isReport(setting)) {
    return getReportLabelBySettings(culture, setting);
  }

  if (isEntity(setting)) {
    return getLocalizedName(setting);
  }

  if (isStatic(setting)) {
    return setting.name;
  }

  return undefined;
};

export const staticEntries = (): StaticEntry[] => [
  { name: "Dashboard", label: _("Dashboard"), icon: "fa-pie-chart" },
  { name: "Reports", label: _("Reports"), icon: "fa-table" },
  {
    name: "Scheduler",
    label: _("Scheduler"),
    icon: "fa-calendar-o",
    requiredFeatures: ["scheduler"],
  },
  {
    name: "Scheduler2",
    label: _("Scheduler 2.0"),
    icon: "fa-calendar-o",
    requiredFeatures: ["scheduler2"],
  },
  {
    name: "PartsReorderList",
    label: _("Parts Reorder List"),
    icon: "fa-list-alt",
    requiredFeatures: ["partsReorderList"],
  },
];

export const entityToSetting = (e: Entity): EntitySetting => ({
  entity: e.name,
  localizedName: getLocalizedName(e),
  icon: getCss(e),
  hidden: e.type === "SubEntity" && !behaveAs("Assignment", e),
});

export const getEntitySettings = (entities: Entities): EntitySetting[] =>
  Object.values(entities)
    .filter(
      (e) =>
        (e.type === "Entity" || e.type === "SubEntity") &&
        !e.isSystem &&
        e.isEnabledForSidePanel &&
        !e.displayAsSystem,
    )
    .map(entityToSetting);

export const getAvailableUserMenuSettings = (
  context: Context,
): Array<StaticSetting | EntitySetting> => {
  const { entities, tenant } = context;
  const { features } = tenant;

  const staticSettings = staticEntries()
    .filter(
      (s) =>
        (!s.requiredFeatures ||
          s.requiredFeatures.every((f) => features.includes(f))) &&
        canView(context, s.name),
    )
    .map(staticToSetting);

  const entitySettings = getEntitySettings(entities);

  return [...staticSettings, ...entitySettings].map((setting, position) => ({
    ...setting,
    position: position + 1,
  }));
};

export const filterByEnabledForSidePanel = (
  settings: MenuEntrySetting[],
  entities: Entities,
) =>
  settings.filter(
    (s) => !isEntity(s) || entities[s.entity]?.isEnabledForSidePanel,
  );

export const applyUserSettings =
  (userSettings: MenuEntrySetting[]) =>
  (
    setting: EntitySetting | StaticSetting,
    settings: MenuEntrySetting[],
    find: (s: MenuEntrySetting) => boolean,
  ) => {
    // could contain only Entity or Static Setting
    const entry = userSettings.find(find);

    if (!entry) {
      return [
        ...settings,
        {
          ...setting,
          position: userSettings.length ? userSettings.length + 1 : undefined,
        },
      ];
    }

    return [
      ...settings,
      {
        ...setting,
        icon: entry.icon,
        position: entry.position,
        hidden: entry.hidden,
      },
    ];
  };

export const compareByPosition = (a: MenuEntrySetting, b: MenuEntrySetting) =>
  (a?.position || 0) - (b?.position || 0);

export const getMergedMenuSettings = (
  availableSettings: Array<EntitySetting | StaticSetting>,
  userSettings: MenuEntrySetting[],
): MenuEntrySetting[] => {
  const userSettingsWithPosition = userSettings.map((s, position) =>
    s.position ? s : { ...s, position: position + 1 },
  );

  const reduceUserSettings = applyUserSettings(userSettingsWithPosition);

  const availableMerged = availableSettings.reduce<MenuEntrySetting[]>(
    (acc, s) =>
      isEntity(s)
        ? reduceUserSettings(
            s,
            acc,
            (v) => isEntity(v) && v.entity === s.entity,
          )
        : reduceUserSettings(s, acc, (v) => isStatic(v) && v.name === s.name),
    [],
  );

  const reports = userSettingsWithPosition.filter(isReport);
  return [...availableMerged, ...reports].sort(compareByPosition);
};

export const settingToEntry =
  (
    site: Site,
    culture: Culture,
    entities: Entities,
    recentlyViewed: RecentlyViewed[],
  ) =>
  (ms: MenuEntrySetting): MenuEntry => {
    if (isReport(ms)) {
      return {
        canExplore: false,
        label: getReportLabelBySettings(culture, ms),
        icon: ms.icon,
        link: `#/${site.name}/Reports/${ms.reportId}/Table`,
        itemClass: getMenuItemClass(`${ms.reportId}`),
      };
    }

    if (isEntity(ms)) {
      const entity = entities[ms.entity];
      const entityName = ms.entity;

      const lastViewed = recentlyViewed.filter(
        (lv) => lv.entity === entityName && lv.site === site.name,
      );

      const lastViewedLink = !lastViewed.length
        ? ""
        : `#/${site.name}/${ms.entity}/${lastViewed[0].id}`;

      const permission = entity ? "" : "/nopermissions";

      return {
        label: getLocalizedName(entity) || `[${ms.entity}]`,
        icon: ms.icon,
        canExplore: true,
        entityName: ms.entity,
        link: `#/${site.name}/${ms.entity}${permission}`,
        lastViewedLink,
        itemClass: getMenuItemClass(ms.entity),
      };
    }

    if (isStatic(ms)) {
      const { name } = ms;
      const entry = staticEntries().find((e) => e.name === name);
      if (!entry) return undefined;
      return {
        canExplore: false,
        label: entry.label,
        icon: ms.icon,
        link: `#/${site.name}/${name}`.replace("/Dashboard", "/dashboard"),
        itemClass: getMenuItemClass(name),
      };
    }

    return undefined;
  };

export const getUserMenuEntries = (context: Context): MenuEntry[] => {
  const { entities, preferenceService, site, uiFormat, recentlyViewed } =
    context;
  const { menu } = preferenceService.get();
  const settings = getAvailableUserMenuSettings(context);

  const menuSettings = getMergedMenuSettings(settings, menu).filter(
    (s) => !s.hidden,
  );

  return menuSettings
    .map(settingToEntry(site, uiFormat.culture, entities, recentlyViewed.get()))
    .filter((e) => !!e);
};

export const equalsSetting = (
  s1: MenuEntrySetting,
  s2: MenuEntrySetting,
): boolean =>
  (isReport(s1) && isReport(s2) && s1.reportId === s2.reportId) ||
  (isEntity(s1) && isEntity(s2) && s1.entity === s2.entity) ||
  (isStatic(s1) && isStatic(s2) && s1.name === s2.name);

export const track = (entityName: string) => () =>
  trackEvent(eventLabel.lastViewed, { entity: entityName });

export const getListSettings = (settings: MenuEntrySetting[]) =>
  settings.filter((s) => !s.hidden);

export const sortSettingsByPosition = (settings: MenuEntrySetting[]) =>
  settings.sort(compareByPosition);

export const mapEntityLocalizedName =
  (entities: Entities) => (setting: MenuEntrySetting) =>
    isEntity(setting)
      ? { ...setting, localizedName: entities[setting.entity].localizedName }
      : setting;
